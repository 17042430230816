gsap.registerPlugin(ScrollTrigger);

const darkBackground = document.querySelector('.dark-background');
darkBackground.addEventListener('click', closeWindow);

const closeWindowButtons = document.querySelectorAll('.close-window-button');
closeWindowButtons.forEach((el) => el.addEventListener('click', closeWindow));

document.querySelectorAll('.featured-pieces__item--outer').forEach((el) => {
	el.addEventListener('click', openWindow);
	el.addEventListener('touch', openWindow);
});

window.addEventListener('keydown', (e) => {
	const activeElement = document.activeElement;
	const isOuterDiv = activeElement?.classList.contains('featured-pieces__item--outer') || false;

	if (e.key === 'Escape') {
		e.preventDefault();
		closeWindow(e);
	} else if ((e.key === 'Enter' || e.key === ' ') && isOuterDiv) {
		e.preventDefault();
		openWindow({ target: activeElement })
	}
})

let currentOpenWindow;
let previousFocusedElement;
function openWindow(e) {
	e?.preventDefault?.();
	let target = e.target.closest('.featured-pieces__item--outer');
	currentOpenWindow = target.nextElementSibling;


	currentOpenWindow?.style.setProperty('display', 'block');
	darkBackground.style.setProperty('z-index', 103);
	darkBackground.style.setProperty('opacity', 1);

	preventScroll();

	previousFocusedElement = document.activeElement;
	const closeButton = currentOpenWindow?.querySelector('.close-window-button');
	closeButton?.focus();
	closeButton?.focus()
}

function closeWindow(e) {
	if (!currentOpenWindow) return;

	currentOpenWindow?.style.setProperty('display', 'none');
	darkBackground.style.setProperty('z-index', -10);
	darkBackground.style.setProperty('opacity', 0);

	currentOpenWindow?.querySelector('audio')?.pause();
	currentOpenWindow = null;
	enableScroll();

	previousFocusedElement?.focus();
}

function addMouseListenersHomePage() {
	document
		.querySelectorAll('.featured-pieces__item--outer')
		.forEach((el) => {
			el.addEventListener('mouseenter', mouseEnterHandlerIndex.bind(this, el));
			el.addEventListener('mouseleave', mouseLeaveHandlerIndex.bind(this, el));
		});
}

function mouseEnterHandlerIndex(el) {
	let overlay = el.querySelector('.overlay');
	let inner = el.querySelector('.featured-pieces__item--inner');
	let innerH3 = el.querySelector('.featured-pieces__item--inner h3');
	let innerH4 = el.querySelector('.featured-pieces__item--inner h4');
	gsap.to(inner, {
		duration: 0.2,
		opacity: 1,
	});
	gsap.to(overlay, {
		duration: 0.2,
		opacity: 0.75,
	});
	gsap.to(innerH3, {
		color: 'white',
	})
	gsap.to(innerH4, {
		color: 'white',
	})
}

const mouseLeaveHandlerIndex = (el) => {
	let overlay = el.querySelector('.overlay');
	let inner = el.querySelector('.featured-pieces__item--inner');
	let innerH3 = el.querySelector('.featured-pieces__item--inner h3');
	let innerH4 = el.querySelector('.featured-pieces__item--inner h4');
	gsap.to(inner, {
		duration: 0.2,
		opacity: 0,
	});
	gsap.to(overlay, {
		duration: 0.2,
		opacity: 0,
	});
	gsap.to(innerH3, {
		color: 'transparent',
	})
	gsap.to(innerH4, {
		color: 'transparent',
	})
};

//unhide featured pieces
document.querySelector('.featured-pieces').style.opacity = 1;

//change color of header/nav on desktop
ScrollTrigger.matchMedia({
	//mobile
	'(max-width: 899px)': function () {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.slanted-div',
				start: 'top top', // when the top of the trigger hits the top of the viewport
				end: 'bottom top', // end after scrolling 500px beyond the start
				scrub: true,
			},
		});
		tl.fromTo(
			'.menu-toggle',
			{
				backgroundColor: 'transparent',
			},
			{
				backgroundColor: 'white',
			}
		);
		tl.fromTo(
			'header, nav',
			{
				backgroundColor: 'white',
			},
			{
				backgroundColor: 'white',
			}
		);
	},
	// desktop
	'(min-width: 900px)': function () {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.slanted-div',
				start: 'top top', // when the top of the trigger hits the top of the viewport
				end: 'bottom top', // end after scrolling 500px beyond the start
				scrub: true,
			},
		});
		tl.fromTo(
			'nav, header',
			{
				backgroundColor: CREAM,
			},
			{
				backgroundColor: 'white',
			}
		);
	},
});

const playAudio = (e) => {
	try {
		let audio = document.querySelector('.pronunciation-audio');
		audio.play();
	} catch (error) {
		console.error(error);
	}
};

//pronunciation audio player
let pronunciationText = document.querySelector('.pronunciation');
pronunciationText.addEventListener('click', (e) => playAudio(e));
pronunciationText.addEventListener('touch', (e) => playAudio(e));

//Resizing options
window.addEventListener('resize', handleResize);
let featuredPiecesItemOuter16th;
let windowSizeHomePage;
function handleResize() {
	let columns = Math.floor(width / 160);

	/* Set size of featured container */
	gsap.set('.featured-pieces', {
		width: Math.min(columns * 160, 900),
		opacity: 1,
	});
}

addMouseListenersHomePage();
handleResize();
